<template>
  <div class="practiceList">
    <el-scrollbar
      class=""
      style="height: 812px"
      :native="false"
      id="resultScroll"
      ref="myScrollbar"
    >
      <el-tabs
        v-model="answer"
        @tab-click="handleClick"
        stretch
        v-if="isBrush == 1"
      >
        <el-tab-pane
          :label="item.label"
          :name="item.name"
          v-for="item in tabsList"
          :key="item.name"
        ></el-tab-pane>
      </el-tabs>
      <div v-if="type == 3">
        <div
          v-for="item in list"
          :key="item.Id"
          class="listItem"
          @click="gotoAnalysis(item)"
        >
          <div style="color: #333; font-size: 16px; font-weight: bold">
            {{ item.Name }}
          </div>
          <div v-html="item.Explain" class="explain"></div>
        </div>
      </div>
      <div v-if="type == 2 || type == 1">
        <div v-for="(li, i) in list" :key="i" class="topicBox">
          <div class="left">
            <div style="color:#000">
              {{ li.PaperName || li.ChapterName }}
              <span style="color: #1288f4"
                >【{{ li.AllQuestionCount }}道题】</span
              >
            </div>
            <div style="color: #999999">练习时间：{{ li.QustionTime }}</div>
          </div>
          <div class="right">
            <div style="color: #5a5a5a">
              答对：{{
                li.CorrectQuestionCount == null ? 0 : li.CorrectQuestionCount
              }}道题
            </div>
            <div @click="gotoAnalysis(li)" style="color: #1288f4">查看详情</div>
          </div>
        </div>
      </div>
      <div v-if="list.length <= 0">
        <no-data></no-data>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  questionBankQueryUserWordsByAnswers,
  questionBankQueryUserPaperRecord,
  questionBankQueryUserChapterRecord,
} from "@/api/personal";
import noData from "@/components/noData";
export default {
  data() {
    return {
      answer: "0",
      tabsList: [
        { name: "0", label: "熟悉" },
        { name: "1", label: "不熟悉" },
        { name: "2", label: "陌生" },
      ],
      page: 1,
      pageSize: 12,
      userInfo: {},
      courseId: 0,
      list: [],
      more_show: true, // 标志是否可以加载更多
      loading: false, // 只有在列表数据获取之后才可以设置为ture 在滚动加载更多的时候使用
      total: 0,
      pageCount: 0,
      isBrush: 0, //是否是刷题记录 0 否 1是
      type: 1, //1 练习记录 2考试记录 3刷题记录
    };
  },
  components: {
    noData,
  },
  created() {
    this.courseId = this.$route.query.CourseId;
    this.isBrush = this.$route.query.isBrush;
    this.type = this.$route.query.type;
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    this.studentId = this.$route.query.UserID;
    if (this.type == 1) {
      
      this.initPractice();
    } else if (this.type == 2) {
      this.initExamination();
    } else {
      this.initBrush();
    }
  },
  mounted() {
    var that = this;
    // 监听滚动事件
    document
      .getElementById("resultScroll")
      .addEventListener("scroll", that.handleScroll, true);
  },
  methods: {
    handleScroll() {
      var that = this;
      var sh = that.$refs["myScrollbar"].$refs["wrap"].scrollHeight; // 滚动条高度
      var st = that.$refs["myScrollbar"].$refs["wrap"].scrollTop; // 滚动条距离顶部的距离
      var ch = that.$refs["myScrollbar"].$refs["wrap"].clientHeight; // 滚动条外容器的高度
      if (st + ch >= sh) {
        //到底了-业务逻辑

        that.page += 1;
        if (that.page <= that.pageCount) {
          that.initBrush();
        }
      }
    },
    //前去解析
    gotoAnalysis(item) {
      if(this.$route.query.courseActive == "false")
      {
        this.$message.error("对不起，当前课程不在有效期内，不能查看");
        return false;
      }
      console.log(item);
      // H5/topicPaper?Id=15&Name=2021《文艺理论》直播课&UserID=65
      let path = "";
      let query = { DataId: item.DataId };
      if (this.type == 1) {
        path = "/H5/practiceRecordDetails";
      } else if (this.type == 2) {
        path = "/H5/examRecordDetails";
      } else {
        path = "/H5/topicPaper";
        query = { Id: item.Id, Name: item.Name, UserID: this.$route.query.UserID,CourseId: this.$route.query.CourseId,WordId:item.Id,Answer:this.answer,questionCategory:-1}
      }
      this.$router.push({ path, query });
    },
    handleClick() {
      this.initBrush(1);
    },
    //获取练习记录列表
    async initPractice() {
      //let studentId = this.userInfo.Id;
      let data =
        "?courseId=" +
        this.courseId +
        "&studentId=" +
        this.studentId +
        "&pageIndex=" +
        this.page +
        "&pageSize=" +
        this.pageSize;
      const res = await questionBankQueryUserChapterRecord(data);
      console.log(res);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.total = res.response.dataCount;
        this.pageCount = res.response.pageCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取考试记录列表
    async initExamination() {
      //let studentId = this.userInfo.Id;
      let data =
        "?courseId=" +
        this.courseId +
        "&studentId=" +
        this.studentId +
        "&pageIndex=" +
        this.page +
        "&pageSize=" +
        this.pageSize;
      const res = await questionBankQueryUserPaperRecord(data);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.total = res.response.dataCount;
        this.pageCount = res.response.pageCount;
      } else {
        this.$message.error(res.msg);
      }
    },
    //获取刷题记录列表
    async initBrush(NUM) {
      //let studentId = this.userInfo.Id;
      let parm = {
        courseId: this.courseId,
        studentId: this.studentId,
        pageIndex: (this.page = NUM == 1 ? 1 : this.page),
        pageSize: this.pageSize,
        answer: this.answer,
      };
      const res = await questionBankQueryUserWordsByAnswers(parm);
      if (res.success === true) {
        if (NUM == 1) {
          this.list = res.response.data;
        } else {
          this.list = this.list.concat(res.response.data);
        }
        this.total = res.response.dataCount;
        this.pageCount = res.response.pageCount;
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>
<style>
.el-scrollbar__thumb {
  width: 0 !important;
}
</style>
<style lang="less" scoped>
.practiceList {
  box-sizing: border-box;
  padding: 0 15px;
  background: #fff;
  .listItem {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    border-bottom: 1px solid #ebedef;
    margin-bottom: 10px;
    .btn {
      font-size: 11px;
      color: #fff;
      width: 58px;
      height: 18px;
      border-radius: 9px;
      background: #ff795c;
      text-align: center;
      line-height: 18px;
      position: absolute;
      margin: 0;
      padding: 0;
      right: 0;
      top: 0;
    }
  }
  /deep/.explain {
    color: #a1a1a1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /deep/.explain > * {
    display: none;
  }
  /deep/.explain p:nth-child(1) {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .min_container {
    padding-top: 1.1rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
  }
  .topicBox {
    height: 64px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebedef;
    .left,
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .right {
      align-items: flex-end;
    }
  }
}
</style>